
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'test-stats-preview'
})
export default class TestStatsPreview extends Vue {
  @Prop() pages!: number;
  @Prop() exercises!: number;
  @Prop() scores!: number;
  @Prop() attachments!: number;
  @Prop() time!: number;
  @Prop() isSuperUser!: boolean;
  @Prop() isRedactorExamOrNsml!: boolean;
}
