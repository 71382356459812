
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Page, TestHeader, TestModel, TestTypeIdEnum } from '@/models';
import EditableExercise from '@/views/Generator/TestComposer/components/EditableExercise';
import { Scale } from '@/shared/components';
import { pageDimensions, ROLES } from '@/config';
import { isRedactorExamOrNsml } from '@/helpers/roles';
import { namespace } from 'vuex-class';

const AppStore = namespace('AppStore');

@Component({
  name: 'GtTestPage',
  components: { EditableExercise, Scale }
})

export default class GtTestPage extends Vue {
  pageDimensions = pageDimensions;

  @Prop() exercisesInVariantLength: number;
  @Prop() group: string;
  @Prop() header: TestHeader;
  @Prop({ default: false }) isTestPreview: boolean;
  @Prop() isEarlySchoolEducation: boolean | null;
  @Prop({ default: false }) allExercisesLoaded: boolean;
  @Prop() page: Page[];
  @Prop() pageIndex: number;
  @Prop() scaleFactor: number;
  @Prop() score: number;
  @Prop() test: TestModel;
  @Prop() time: number;
  @Prop() title: string;
  @Prop() variantIndex: number;

  @AppStore.State('userRole') userRole!: ROLES[];

  get isRedactorExamOrNsml (): boolean {
    return isRedactorExamOrNsml(this.userRole);
  }

  get isWorkCard (): boolean {
    return this.test.generatorTestTypeId === TestTypeIdEnum.WORK_CARD;
  }

  get testPagesFlat () {
    return this.testPages.flat();
  }

  getExerciseNumber (exercise: never, group: number) {
    return !this.isRedactorExamOrNsml
      ? (
        this.test.variants[group].pages.reduce((acc: any, val: any) => acc.concat(val), []).indexOf(exercise) + 1
      )
      : this.testPagesFlat.indexOf(exercise) + 1;
  }

  get testPages () {
    return this.test.variants && this.test.variants[0] ? this.test.variants[0].pages : [[]];
  }

  isFirst (pageIndex: number, exerciseIndex: number, exercise: never) {
    return !this.isRedactorExamOrNsml
      ? (
        (this.testPages[0].length === 0 && this.getExerciseNumber(exercise, 0) === 1) ||
      (pageIndex === 0 && exerciseIndex === 0)
      )
      : exerciseIndex === 0;
  }

  isLast (pageIndex: number, exerciseIndex: number, group: number) {
    return !this.isRedactorExamOrNsml
      ? (
        pageIndex === this.test.variants[group].pages.length - 1 &&
      exerciseIndex === this.test.variants[group].pages[pageIndex].length - 1
      )
      : exerciseIndex === this.testPagesFlat.length - 1;
  }

  @Emit() onCmpDidLoad () {}
  @Emit() onCmpDidUpdate () {}

  cmpDidLoad () {
    this.onCmpDidLoad();
  }

  cmpDidUpdate () {
    this.onCmpDidUpdate();
  }
}

