
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Page, TestAttachment, TestModel, TestTypeIdEnum } from '@/models';
import { EditTestEvent, TestGroupChangeEvent } from '@/models/tests';
import TestStatsPreview from './TestStatsPreview';
import TestPreviewHeader from './TestPreviewHeader';
import GtTestModalPreview from './TestModalPreview';
import TestPreviewTabs from './TestPreviewTabs';
import { isConsumerRole, isRedactorExamOrNsml, isSuperUser } from '@/helpers/roles';
import { GTLinkTypes, ROLES } from '@/config';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from '@/shared/components';
import { GET_ANSWER_CARD_NOT_DOWNLOADABLE, GET_TEST_TIME } from '@/store/list';
import { namespace } from 'vuex-class';
import { CookieName } from '@/core/cookies';

const TestStore = namespace('TestStore');

@Component({
  name: 'TestPreview',
  components: {
    TestPreviewHeader,
    TestStatsPreview,
    GtTestModalPreview,
    TestPreviewTabs,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton
  }
})
export default class TestPreview extends Vue {
  @Prop() categoryLabel!: string;
  @Prop() currentTest!: TestModel;
  @Prop() isModalOpen: boolean;
  @Prop() testSkillLabel!: string;
  @Prop() testTitle!: string;
  @Prop() userRole: ROLES[];

  leftGroupIndex = 0;
  rightGroupIndex = 1;
  isLeftAnswerKeyEnabled = false;
  isRightAnswerKeyEnabled = false;
  linksTypes = GTLinkTypes;
  testTypeIdEnum = TestTypeIdEnum;

  @TestStore.Getter(GET_TEST_TIME) testTime: number;
  @TestStore.Getter(GET_ANSWER_CARD_NOT_DOWNLOADABLE) isAnswerCardNotDownloadable: boolean;

  get isSuperUser (): boolean {
    return isSuperUser(this.userRole);
  }

  get isRedactorExamOrNsml (): boolean {
    return isRedactorExamOrNsml(this.userRole);
  }

  get isConsumerRole (): boolean {
    return isConsumerRole(this.userRole);
  }

  get isRightGroupShown (): boolean {
    return this.currentTest.variants.length > 1;
  }

  get attachments (): TestAttachment[] {
    return this.currentTest.attachments || [];
  }

  get pdfFiles (): string[] {
    return this.currentTest.files || [];
  }

  get pagesCounter (): number {
    return this.currentTest.variants[0].pages.length;
  }

  get exercisesCounter (): number {
    return this.currentTest.variants[0].pages.reduce((acc: any, val: any) => acc.concat(val), []).length;
  }

  get scoresCounter (): number {
    return this.currentTest.variants[0].pages
      .reduce((acc: any, val: any) => acc.concat(val), [])
      .reduce((prev: any, cur: any) => prev + parseInt(cur.score, 10), 0);
  }

  get attachmentsCounter (): number {
    const pages = this.currentTest.variants.length ? this.currentTest.variants[0].pages : [];
    return pages
      .reduce((acc: any, val: any) => acc.concat(val), [])
      .filter((exercise: Page) => exercise.attachments.length > 0).length;
  }

  get groupsClasses (): any[] {
    return ['gt-test-preview__groups', { 'gt-test-preview__groups--single': !this.isRightGroupShown }];
  }

  fileUrlWithHash (url: string): string {
    const userHash = this.$cookies.get(CookieName.USER_HASH);
    return `${this.$apiURL}${url},${userHash}`;
  }

  @Watch('isModalOpen')
  resetGroups (isOpen: boolean) {
    if (isOpen) {
      this.leftGroupIndex = 0;
      this.rightGroupIndex = 1;
      this.isLeftAnswerKeyEnabled = false;
      this.isRightAnswerKeyEnabled = false;
    }
  }

  @Emit()
  editTest (): EditTestEvent {
    return {
      testId: this.currentTest.id,
      canEdit: this.currentTest.canDelete,
      testTypeId: this.currentTest.generatorTestTypeId ?? TestTypeIdEnum.TEST
    };
  }

  @Emit('download-test-modal')
  openDownloadModal (): string {
    return (this.currentTest.hash as string);
  }

  showAnswerKey (isLeftPage: boolean): void {
    if (isLeftPage) {
      this.leftGroupIndex = -1;
      this.isLeftAnswerKeyEnabled = true;
    } else {
      this.rightGroupIndex = -1;
      this.isRightAnswerKeyEnabled = true;
    }
  }

  changeGroupPreview (obj: TestGroupChangeEvent): void {
    if (obj.isLeftPage) {
      this.leftGroupIndex = obj.index;
      this.isLeftAnswerKeyEnabled = false;
    } else {
      this.rightGroupIndex = obj.index;
      this.isRightAnswerKeyEnabled = false;
    }
  }

  closeTestPreviewModal (): void {
    this.$emit('close');
    history.pushState('', document.title, window.location.pathname + window.location.search);
  }
}
