
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { GroupMap } from '@/helpers';
import { TestGroupChangeEvent } from '@/models/tests';

@Component({
  name: 'TestPreviewTabs'
})
export default class TestPreviewTabs extends Vue {
  @Prop() left!: boolean;
  @Prop() variants: any[];
  @Prop() currentGroupIndex!: number;
  @Prop() otherGroupIndex!: number;
  @Prop() currentAnswerKeyEnabled!: boolean;
  @Prop() otherAnswerKeyEnabled!: boolean;

  groupMap = GroupMap;

  get answerKeyClass (): any[] {
    return [
      'gt-test-preview-tabs__link',
      'gt-test-preview-tabs__tab',
      {
        'gt-test-preview-tabs__tab--active': this.currentAnswerKeyEnabled,
        'gt-test-preview-tabs__tab--disabled': this.otherAnswerKeyEnabled
      }
    ];
  }

  groupNumberClass (index: number): any[] {
    return [
      'gt-test-preview-tabs__link',
      'gt-test-preview-tabs__tab',
      {
        'gt-test-preview-tabs__tab--active': this.currentGroupIndex === index,
        'gt-test-preview-tabs__tab--disabled': this.otherGroupIndex === index
      }
    ];
  }

  change (index: number): void {
    if (index !== this.otherGroupIndex) {
      this.changeGroup(index);
    }
  }

  @Emit()
  changeGroup (index: number): TestGroupChangeEvent {
    return {
      isLeftPage: this.left,
      index: index
    };
  }

  @Emit()
  showAnswers (): boolean {
    return this.left;
  }
}
