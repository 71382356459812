
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DatabaseSortSelect, PageContent, RecursionTree, SearchBar } from '@/shared/components';
import { StructureStatus, StructureStatusName, TestsResponseModel, TestTypeIdEnum } from '@/models';
import { TestDatabaseSortSelect, TestTiles } from './components';
import { GtPageTypes, SEARCH_MODES } from '@/config';
import { FETCH_TEST_DATABASE, IS_SPP, SET_CATEGORY_KEY, SET_DEFAULTS, SET_PAGE_ID } from '@/store/list';
import { FacetsContainer, SelectedFacet } from '@/models/facets';
import { SortData, SortOrder, TestsFilters } from '@/models/tests';
import { LoadingStatus, LoadingStatusName } from '@/shared/models/loading';
import { RouteName } from '@/router/models';
import { Location } from 'vue-router';
import { getQueryParam } from '@/helpers/router';
import { groupBy } from '@/helpers/array-manipulation';
import { MenuModel } from '@/store/layout-store/types';
import { NeIcon } from '@ne/ne-vue2-lib';
import FiltersNew from '@/shared/components/FiltersNew/FiltersNew.vue';
import { handleParentNodes } from '@/helpers/facets';
import { GtMenu } from '@/views/Generator/components';
import { Source } from '@/models/exercises';

const AppStore = namespace('AppStore');
const TestStore = namespace('TestStore');
const LayoutStore = namespace('LayoutStore');

const defaultPage = 1;
const defaultSearchText = '';
const defaultSortKey = 'dateNewest';
@Component({
  name: 'TestDatabase',
  components: {
    GtMenu,
    TestDatabaseSortSelect,
    TestTiles,
    FiltersNew,
    SearchBar,
    PageContent,
    RecursionTree,
    DatabaseSortSelect,
    NeIcon
  }
})
export default class TestDatabase extends Vue {
  mode = SEARCH_MODES;
  activeFacets: SelectedFacet[] = [];
  searchText = defaultSearchText;
  isSearchCleared = true;
  page = defaultPage;
  sortKey = defaultSortKey;
  isFetchingEnabled = true;
  isOldStructureCookieSet = true;
  sortFields: SortData[] = [
    {
      key: 'testNameAZ',
      sortField: 'name',
      sortOrder: 'asc'
    },
    {
      key: 'testNameZA',
      sortField: 'name',
      sortOrder: 'desc'
    },
    {
      key: 'dateNewest',
      sortField: 'dateModify',
      sortOrder: 'desc'
    },
    {
      key: 'dateOldest',
      sortField: 'dateModify',
      sortOrder: 'asc'
    },
    {
      key: 'numberExercisesAsc',
      sortField: 'exerciseCount',
      sortOrder: 'asc'
    },
    {
      key: 'numberExercisesDesc',
      sortField: 'exerciseCount',
      sortOrder: 'desc'
    }
  ];

  loadingStatus: LoadingStatusName = LoadingStatus.INCOMPLETE;
  isComponentLoading = false;
  isMenuLoaded: boolean = false;

  @LayoutStore.State('menu') menu: MenuModel;
  @AppStore.State('testFacets') facets!: FacetsContainer;
  @AppStore.State('categoryKey') categoryKey: string;
  @AppStore.State('structureStatus') structureStatus: StructureStatusName;
  @TestStore.State('tests') tests: TestsResponseModel;
  @AppStore.State('isFiltersActive') isFiltersActive: boolean;

  get isOldStructureInfoShown (): boolean {
    return this.structureStatus === StructureStatus.OLD && !this.isOldStructureCookieSet;
  }

  get newStructureLink (): Location | null {
    const link = this.menu.mapCategory ? this.menu.mapCategory[0] : null;
    return link ? this.getPillRoute(link.categoryKey) : null;
  }

  get defaultSortData (): SortData {
    return this.sortFields[0];
  }

  get sortData (): SortData {
    return this.sortFields.find(field => field.key === this.sortKey) || this.defaultSortData;
  }

  get sortField (): string {
    return this.sortData.sortField;
  }

  get sortOrder (): SortOrder {
    return this.sortData.sortOrder;
  }

  get totalTest () {
    return this.tests.numberOfTests || 0;
  }

  get lastPage (): number {
    return this.tests.numberOfPages || 1;
  }

  async created (): Promise<void> {
    this.isComponentLoading = true;
    this.isOldStructureCookieSet = this.$cookies.get('gt_hide_test_db_structure_info') === 'true';
    const testInEdition = JSON.parse(`${sessionStorage.getItem('testInEdition')}`);
    const categoryKey = this.$route.params.categoryKey.replace(/,/g, '/');
    this.setPageId(GtPageTypes.TEST_DATABASE);
    if (categoryKey !== this.categoryKey) {
      this.setCategoryKey(categoryKey);
      await this.setDefaults();
    }
    if (testInEdition) {
      sessionStorage.removeItem('testInEdition');
    }
    handleParentNodes(this.facets);
    this.getQueryParams();
    await this.getFilteredTests('', false);
    this.isComponentLoading = false;
    this.isMenuLoaded = true;
    this.handleWorkCardVisibility();
    this.handleTestTypeActivity();
    this.copyAuthorToTestTypeFilter();
  }

  @AppStore.Mutation(SET_PAGE_ID) setPageId: (id: GtPageTypes) => void;
  @AppStore.Mutation(SET_CATEGORY_KEY) setCategoryKey?: any;
  @AppStore.Action(SET_DEFAULTS) setDefaults: any;
  @AppStore.Getter(IS_SPP) isSpp: boolean;
  handleTestTypeActivity (): void {
    const testTypeFacets = this.facets.children.find(facet => facet.key === 'testType');
    if (testTypeFacets) {
      testTypeFacets.children!.forEach((item) => {
        item.active = this.$route.query.facets !== undefined && this.$route.query.facets.includes('testype-' + item.id);
      });
    }
  }

  copyAuthorToTestTypeFilter () {
    const autorFilter = this.facets.children.find(facet => facet.key === 'source');
    const testTypeFilter = this.facets.children.find(facet => facet.key === 'testType');
    if (autorFilter && testTypeFilter && !testTypeFilter.children!.some(item => item.key === 'source')) {
      autorFilter.children!.forEach((item) => {
        if (item.name === Source.MY) testTypeFilter.children?.push(item);
      });
    }
  }

  handleWorkCardVisibility (): void {
    if (this.isSpp) {
      const testTypeFilter = this.facets.children.find(facet => facet.key === 'testType');
      testTypeFilter!.children = testTypeFilter!.children!.filter(item => item.id !== TestTypeIdEnum.WORK_CARD);
    }
  }

  getQueryParams (): void {
    const query = this.$route.query;
    this.page = parseInt(getQueryParam(query.page, defaultPage.toString()));
    this.searchText = getQueryParam(query.search, defaultSearchText);
    this.sortKey = getQueryParam(query.order, defaultSortKey);
    const facets = getQueryParam(query.facets, '');
    if (facets) {
      const groupedFacets = facets.split('_').map(g => {
        const splittedGroup = g.split('-');
        const categoryName = splittedGroup[0];
        const ids = splittedGroup[1];
        return ids.split(',').map(id => ({ nameFiltr: categoryName, filterId: parseInt(id) }));
      });
      this.activeFacets = groupedFacets.flat();
    }
  }

  @TestStore.Action(FETCH_TEST_DATABASE) filterTests: (params: TestsFilters) => Promise<void>;

  setQueryParams (searchText = ''): void {
    const query: {[key: string]: string} = {};
    this.searchText = searchText;
    if (this.page !== defaultPage) query.page = this.page.toString();
    if (searchText !== defaultSearchText) query.search = searchText;
    if (this.sortKey !== defaultSortKey) query.order = this.sortKey;
    if (this.activeFacets.length > 0) {
      const urlFacets: string[] = [];
      const groupedFacets = groupBy(this.activeFacets, 'nameFiltr');
      Object.keys(groupedFacets).forEach((key: any) => {
        const ids = groupedFacets[key].map((f: any) => f.filterId).join(',');
        urlFacets.push(`${key}-${ids}`);
      });
      query.facets = urlFacets.join('_');
    }
    this.$router.replace({ query }).catch(() => {});
  }

  async getFilteredTests (searchText = '', resetPage = true) {
    if (this.isFetchingEnabled) {
      this.loadingStatus = LoadingStatus.INCOMPLETE;
      if (resetPage) this.page = defaultPage;
      this.setQueryParams(searchText);
      const options: TestsFilters = {
        searchText: searchText,
        page: this.page,
        sortField: this.sortField,
        sortOrder: this.sortOrder,
        activeFacets: this.activeFacets,
        categoryKey: this.categoryKey
      };
      this.isSearchCleared = this.searchText === '';
      try {
        await this.filterTests(options);
        this.loadingStatus = LoadingStatus.COMPLETE;
      } catch (e) {
        this.loadingStatus = LoadingStatus.ERROR;
      }
    }
  }

  getPillRoute (categoryKey: string): Location {
    return {
      name: RouteName.TEST_DATABASE,
      params: {
        categoryKey: categoryKey.split('/').join(',')
      }
    };
  }

  disableOldStructureInfo (): void {
    this.isOldStructureCookieSet = true;
    this.$cookies.set('gt_hide_test_db_structure_info', true, Infinity);
  }

  updateSelectedFilters () {
    this.getFilteredTests();
  }

  clearFilterOptions () {
    this.isFetchingEnabled = false;
    this.activeFacets = [];
    this.searchText = '';
    this.isFetchingEnabled = true;
    this.getFilteredTests();
  }
}
