
import { Component, Prop } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { namespace } from 'vuex-class';
import { GET_TEST_TIME, IS_SUPER_USER, VARIANTS_ORDER } from '@/store/list';
import { Page, TestModel } from '@/models';
import { cloneNoRef, GroupMap } from '@/helpers';
import cutTitleMixin from '@/mixins/cutTitleMixin';
import { ratioMap } from '@/config';
import { Scale } from '@/shared/components';
import GtTestPage from '@/shared/components/GtTest/GtTestPage.vue';
import { arrayToPages } from '@/helpers/order';
import { isExerciseOldType } from '@/helpers/exercise';

const AppStore = namespace('AppStore');
const TestStore = namespace('TestStore');

@Component({
  name: 'TestModalPreview',
  components: { Scale, GtTestPage }
})
export default class TestModalPreview extends mixins(cutTitleMixin) {
  @Prop() variantIndex!: number;
  @Prop() answer!: boolean;
  @Prop() isRedactorExamOrNsml!: boolean;

  ratioMap = ratioMap
  scaleFactor = 0.70;
  exerciseLoadedCount = 0;
  allExercisesLoaded = false;

  @AppStore.Getter(IS_SUPER_USER) isSuperUser: boolean;
  @TestStore.State('testPreView') testPreview: TestModel;
  @TestStore.Getter(GET_TEST_TIME) testTime!: number;
  @TestStore.Action(VARIANTS_ORDER) variantsOrder: any;

  getComment (exercise: Page): string | null {
    if (this.isSuperUser) return exercise.comment;
    return isExerciseOldType(exercise.answersType) ? exercise.comment : null;
  }

  getIsOldExercise (exercise: Page): boolean {
    return isExerciseOldType(exercise.answersType) as boolean;
  }

  handleAllExercisesLoad () {
    this.exerciseLoadedCount++;
    if (this.exerciseLoadedCount === this.flatTestPages?.length) {
      this.reorder();
      this.allExercisesLoaded = true;
    }
  }

  reorder () {
    this.testPreview.variants[this.variantIndex].pages = cloneNoRef(arrayToPages(this.flatTestPages, this.testPreview.earlySchoolEducation));
  }

  mounted () {
    setTimeout(() => {
      if (!this.allExercisesLoaded && this.testPreview.variants[this.variantIndex]?.pages) {
        this.reorder();
      }
    }, 3_000);
  }

  get getTestTitle () {
    return this.testPreview.title;
  }

  getAttachmentName (exercise: Page): string | null {
    return exercise.attachments[0]?.filename ?? null;
  }

  getHeaderHeight (vIndex: number) {
    if (vIndex === 0) {
      return this.testPreview.title && this.testPreview.title.length >= 50 ? 85 : 60;
    }
  }

  getGroup (vIndex = 0) {
    return this.answer ? GroupMap.get(vIndex) : GroupMap.get(this.variantIndex);
  }

  getExerciseNumber (exercise: never, vIndex = 0) {
    if (this.answer) {
      return (
        this.testPreview.variants[vIndex].pages.reduce((acc: any, val: any) => acc.concat(val), []).indexOf(exercise) +
        1
      );
    }
    return (
      this.testPreview.variants[this.variantIndex].pages
        .reduce((acc: any, val: any) => acc.concat(val), [])
        .indexOf(exercise) + 1
    );
  }

  get score () {
    if (this.testPreview) {
      return this.testPreview.variants[0].pages
        .reduce((acc: any, val: any) => acc.concat(val), [])
        .reduce((prev: any, cur: any) => prev + parseInt(cur.score, 10), 0);
    } else {
      return 0;
    }
  }

  get testPages (): Page[][] {
    if (this.testPreview.variants[this.variantIndex]?.pages) return this.testPreview.variants[this.variantIndex].pages;
    else return [[]];
  }

  get flatTestPages () {
    return this.testPages.flat();
  }

  get variants () {
    return this.testPreview ? this.testPreview.variants : 0;
  }
}
