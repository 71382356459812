import { Vue, Component } from 'vue-property-decorator';

@Component
export default class cutTitleMixin extends Vue {
  cutTitle (element: string, number: number) {
    const toEnd = number - 3;
    element = element.trim();
    if (element.length > number) {
      element = `${element.slice(0, toEnd)}...`;
    }
    return element;
  }
}
