
import { Component, Prop } from 'vue-property-decorator';
import { TestAttachment, TestModel } from '@/models';
import { GTLinkTypes, ROLES } from '@/config';
import { FileIcon } from '@/shared/components';
import { mixins } from 'vue-class-component';
import getIconTypeMixin from '@/mixins/getIconTypeMixin';
import { namespace } from 'vuex-class';
import { LayoutModel } from '@/store/layout-store/types';
import DownloadTestLinks from '@/shared/components/DownloadTestLinks/DownloadTestLinks.vue';

const AppStore = namespace('AppStore');
const LayoutStore = namespace('LayoutStore');

@Component({
  name: 'TestPreviewHeader',
  components: { DownloadTestLinks, FileIcon }
})

export default class TestPreviewHeader extends mixins(getIconTypeMixin) {
  @Prop() categoryLabel!: string;
  @Prop() currentTest!: TestModel;
  @Prop() testTitle!: string;
  @Prop() attachments!: TestAttachment[];
  @Prop() testSkillLabel!: string;
  @Prop() isAnswerCardDownloadable!: boolean;
  resourceTypes = GTLinkTypes;

  @AppStore.State('userRole') userRole: ROLES[];
  @LayoutStore.State('layout') layout: LayoutModel;

  get showOndorio (): boolean | null {
    return this.layout.user && this.layout.user.isOndorio;
  }

  get isRedactorGT (): boolean {
    return this.userRole.includes(ROLES.REDACTOR_GT);
  }
}
