
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
  EXERCISES_ORDER,
  EXERCISES_REMOVE,
  SET_SELECTED_VARIANTS,
  UPDATE_ANSWER_KEY, UPDATE_EXERCISE_PRINT_HEIGHT,
  UPDATE_EXERCISE_VARIANT,
  USER_NOTIFY, VARIANTS_ORDER
} from '@/store/list';
import { AnswerFieldType, DIRECTIONS, pageDimensions, ROLES } from '@/config';
import { Exercise, ExerciseDetails, Page } from '@/models';
import { ExerciseInCurrentTest, ExerciseVariant, ExerciseAnswerField } from '@/models/exercises';
import { find } from '@/helpers/array-manipulation';
import { ActionButton, Scale } from '@/shared/components';
import { NeIcon } from '@ne/ne-vue2-lib';

const AppStore = namespace('AppStore');
const ExerciseStore = namespace('ExerciseStore');
const TestStore = namespace('TestStore');

@Component({
  name: 'EditableExercise',
  components: {
    ActionButton,
    NeIcon,
    Scale
  }
})
export default class EditableExercise extends Vue {
  @Prop() earlySchoolEducation: boolean;
  @Prop() exercise!: Page;
  @Prop() exerciseIndex: number;
  @Prop() first: any;
  @Prop() groupIndex: number;
  @Prop() indexInTest: number;
  @Prop() last: any;
  @Prop() page!: Page[];
  @Prop() pageIndex: any;
  @Prop() scaleFactor: number;
  @Prop() scoring!: boolean;
  @Prop({ default: false }) isRedactorExamOrNsml: boolean;
  @Prop({ default: false }) allExercisesLoaded: boolean;
  @Prop({ default: false }) isTestPreview: boolean;
  @Prop({ default: false }) isWorkCard: boolean;

  answerFieldType = AnswerFieldType;
  direction = DIRECTIONS;
  exerciseDetails: ExerciseDetails;

  exerciseWebComponentData: any = null;
  exerciseVariant = {};
  exerciseDidSendPrintHeightOnLoad = false;
  variantIndex = 0;

  @ExerciseStore.State('cachedExercises') cachedExercises: Exercise[];
  @AppStore.State('step') step: number;

  get isScoreVisible (): boolean {
    return !this.isWorkCard;
  }

  get forceAdd () {
    const answerFieldMarginTop = 5;
    if (this.earlySchoolEducation) return this.exercise.printHeight + answerFieldMarginTop <= pageDimensions.earlyEducationHeaderHeight;
    return this.exercise.printHeight + answerFieldMarginTop <= pageDimensions.pageContentHeight;
  }

  get exerciseAnswers () {
    const answers = this.exerciseDetails.variants[this.variantIndex].answers;
    return answers ? JSON.stringify(answers) : null;
  }

  @AppStore.Action(USER_NOTIFY) notify: any;
  @TestStore.Action(EXERCISES_REMOVE) exerciseRemove: any;
  @TestStore.Action(EXERCISES_ORDER) orderExercise: any;
  @ExerciseStore.Action(UPDATE_ANSWER_KEY) updateAnswerKey: (exerciseAnswer: ExerciseAnswerField) => void;
  @ExerciseStore.Action(UPDATE_EXERCISE_VARIANT) updateVariant: any;
  @TestStore.Mutation(UPDATE_EXERCISE_PRINT_HEIGHT) updatePrintHeight: any;
  @ExerciseStore.Mutation(SET_SELECTED_VARIANTS) selectVariant!: any;
  @TestStore.Action(VARIANTS_ORDER) variantsOrder: any;

  @Watch('exercise', { deep: true })
  exerciseWatchHandler (nextExercise: ExerciseInCurrentTest) {
    this.exerciseVariant = nextExercise.variantId;
    this.exerciseDetails = this.getExerciseDetail(this.exercise.id);
    this.loadExerciseWebComponent();
  }

  @Watch('cachedExercises', { deep: true })
  cachedExercisesChangeHandler () {
    this.exerciseDetails = this.getExerciseDetail(this.exercise.id);
    this.loadExerciseWebComponent();
  }

  mounted () {
    this.loadExerciseWebComponent();
  }

  @Emit()
  removeExercise (exercise: Page) {
    this.exerciseRemove(exercise);
    this.notify(this.$tc('NOTIFICATIONS.exercise_was_detached_from_test'));
  }

  created () {
    this.exerciseVariant = this.exercise.variantId;
    this.exerciseDetails = this.getExerciseDetail(this.exercise.id);
    if (this.exerciseDetails) {
      this.variantIndex = this.exerciseDetails.variants.findIndex(
        (variant: ExerciseVariant) => variant.id === this.exerciseVariant
      );
    }
  }

  reOrderExercise (direction: any, doSwap = false) {
    this.orderExercise({
      exerciseIndex: this.exerciseIndex,
      pageIndex: this.pageIndex,
      variantIndex: this.groupIndex,
      direction,
      doSwap,
      isRedactorExam: this.isRedactorExamOrNsml
    });
  }

  isVariantActive (variantId: number) {
    return this.exercise.variantId === variantId;
  }

  loadExerciseWebComponent () {
    try {
      this.variantIndex = this.exerciseDetails.variants.findIndex(
        (variant: ExerciseVariant) => variant.id === this.exerciseVariant
      );
      this.exerciseWebComponentData = null;
      this.exerciseWebComponentData = {
        answerFieldCount: this.exercise.answerFieldCount,
        answerFieldType: this.exercise.answerFieldType,
        answerKey: this.exercise.answerKey,
        answersType: this.exerciseDetails.answersType,
        attachments: this.exercise.attachments,
        earlySchoolEducation: this.exerciseDetails.earlySchoolEducation,
        id: this.exerciseDetails.id,
        printHeight: this.exerciseDetails.variants[this.variantIndex].printHeight,
        score: this.exerciseDetails.score,
        solution: this.exerciseDetails.variants[this.variantIndex].solution,
        task: this.exerciseDetails.variants[this.variantIndex].content,
        variants: this.exerciseDetails.variants
      };
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  getExerciseDetail (exerciseId: number) {
    return find(this.cachedExercises, exerciseId);
  }

  changeVariant (variantId: number, vIdx: number) {
    this.exerciseVariant = variantId;
    this.variantIndex = vIdx;
    this.loadExerciseWebComponent();
    this.updateVariant({
      groupIndex: this.groupIndex,
      orderPageIndex: this.pageIndex,
      orderExerciseIndex: this.exerciseIndex,
      selectedVariant: variantId
    });
    if (this.groupIndex === 0) {
      this.selectVariant({ exerciseId: this.exercise.id, variantIndex: vIdx });
    }
    setTimeout(() => {
      this.variantsOrder({
        exerciseIndex: 0,
        pageIndex: 0,
        direction: DIRECTIONS.NONE
      });
    }, 100);
  }

  updateTestAfterVariantChange (): void {
    const currentVariant = this.exerciseDetails.variants[this.variantIndex];
    this.page[this.exerciseIndex].printHeight = currentVariant.printHeight;
    this.reOrderExercise(DIRECTIONS.NONE);
  }

  updateExerciseAnswer (mode: AnswerFieldType) {
    const payload: ExerciseAnswerField = {
      id: this.exercise.id,
      answerKey: true,
      answerFieldType: null,
      answerFieldCount: 1
    };
    switch (mode) {
    case AnswerFieldType.GRID:
      payload.answerFieldType = AnswerFieldType.GRID;
      break;
    case AnswerFieldType.LINES:
      payload.answerFieldType = AnswerFieldType.LINES;
      break;
    case AnswerFieldType.BLANK:
      payload.answerFieldType = AnswerFieldType.BLANK;
      break;
    case AnswerFieldType.THREE_LINE:
      payload.answerFieldType = AnswerFieldType.THREE_LINE;
      break;
    case AnswerFieldType.MOVE_UP:
      payload.answerFieldType = this.exercise.answerFieldType;
      payload.answerFieldCount = this.exercise.answerFieldCount! + 1;
      break;
    case AnswerFieldType.MOVE_DOWN:
      payload.answerFieldType = this.exercise.answerFieldType;
      payload.answerFieldCount = this.exercise.answerFieldCount! - 1;
      if (payload.answerFieldCount <= 0) {
        payload.answerFieldType = null;
        payload.answerFieldCount = 0;
        payload.answerKey = false;
      }
      break;
    case AnswerFieldType.DELETE:
      payload.answerFieldType = null;
      payload.answerFieldCount = 0;
      payload.answerKey = false;
      break;
    }
    this.updateAnswerKey(payload);
    this.loadExerciseWebComponent();
  }

  answerFieldUpdatedd (ev: CustomEvent) {
    if (ev.detail !== 0) {
      this.updatePrintHeight({
        groupIndex: this.groupIndex,
        orderPageIndex: this.pageIndex,
        orderExerciseIndex: this.exerciseIndex,
        printHeight: ev.detail,
        isRedactorExam: this.isRedactorExamOrNsml
      });
      this.reOrderExercise(this.direction.NONE);
    }
  }

  exerciseMenuStyle () {
    return {
      transform: `scale(${1 / this.scaleFactor})`
    };
  }

  cmpDidLoaded (ev: CustomEvent): void {
    this.exerciseDidSendPrintHeightOnLoad = true;
    this.updatePrintHeight({
      groupIndex: this.groupIndex,
      orderPageIndex: this.pageIndex,
      orderExerciseIndex: this.exerciseIndex,
      printHeight: ev.detail.height,
      isRedactorExam: this.isRedactorExamOrNsml
    });
    if (this.allExercisesLoaded) this.reOrderExercise('none');
    this.onCmpLoad(ev.detail.height);
  }

  cmpDidUpdated (ev: CustomEvent): void {
    if (ev?.detail) this.onCmpDidUpdate(ev.detail.height);
  }

  @Emit() onCmpLoad (height: number): number { return height; }

  @Emit() onCmpDidUpdate (height: number): number { return height; }
}
