
import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
import { NeSelect } from '@ne/ne-vue2-lib';
import { SortData } from '@/models/tests';

@Component({
  name: 'TestDatabaseSortSelect',
  components: { NeSelect }
})
export default class TestDatabaseSortSelect extends Vue {
  @PropSync('sortKey') value!: string;
  @Prop() sortFields!: SortData[];

  get items (): any[] {
    return this.sortFields.map(item => {
      return {
        id: item.key,
        text: this.$tc(`SORT.${item.key}`)
      };
    });
  }

  @Emit() change () {}
}
