
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DeleteTestModal from './DeleteTestModal';
import { DatabaseTestModel, TestTypeIdEnum } from '@/models';
import { EditTestEvent } from '@/models/tests';
import { ROLES, TestsAndExamsName, WorkCardName } from '@/config';
import { NeButton } from '@ne/ne-vue2-lib';
import { categoryInfo } from '@/helpers/category-key-info';
import { CategoryInfoLevel } from '@/models/category-info';
import { CLogLevel, ContentType, EventName } from '@/models/gtm';
import { testActivityInfo } from '@/helpers/activity-info';
import { LayoutModel } from '@/store/layout-store/types';
import { testAuthor } from '@/helpers';
import { GET_TEST_TIME } from '@/store/list';
import { ActionButton, Tooltip } from '@/shared/components';
import { isConsumerRole, isSuperUser } from '@/helpers/roles';
import DownloadTestModal
  from '@/views/Generator/TestDatabase/components/TestTiles/TestTile/DownloadTestModal/DownloadTestModal.vue';
import { TranslateResult } from 'vue-i18n';

const AppStore = namespace('AppStore');
const AuthStore = namespace('AuthStore');
const LayoutStore = namespace('LayoutStore');
const TestStore = namespace('TestStore');

@Component({
  name: 'TestTile',
  components: { ActionButton, DownloadTestModal, DeleteTestModal, NeButton, Tooltip }
})
export default class TestTile extends Vue {
  @Prop() test!: DatabaseTestModel;
  @Prop() userRole: ROLES[];
  @Prop() showDownloadTestModal: boolean;

  showDeleteTestModal = false;
  testToDeleteId = 0;
  testTypeIdEnum = TestTypeIdEnum;

  @AppStore.State('categoryKey') categoryKey: string;
  @AuthStore.State('isUserLogged') isUserLogged: boolean;
  @LayoutStore.State('layout') layout: LayoutModel;
  @TestStore.Getter(GET_TEST_TIME) testTime!: any;

  get isConsumerRole (): boolean { return isConsumerRole(this.userRole) }

  get showOndorio (): boolean | null {
    return this.layout.user && this.layout.user.isOndorio;
  }

  get showNewLabel (): boolean {
    const today: number = new Date().getTime();
    const testDateAdd: number = new Date(this.test.dateAdd).getTime();
    const periodTime: number = 1000 * 60 * 60 * 24 * 14;

    if (today - periodTime < testDateAdd && !this.test.userId) {
      return true;
    }
    return false;
  }

  get canDelete (): boolean {
    return this.test.canDelete;
  }

  get urlHash (): string {
    return `#${this.test.categoryId},${this.test.hash}`;
  }

  get isRedactorOrAdmin (): boolean {
    return isSuperUser(this.userRole);
  }

  @Emit('test-in-preview')
  showTestInPreview (): string {
    window.location.href = this.urlHash;
    return this.test.hash;
  }

  @Emit('donwload-test-modal')
  openDownloadModal (): string {
    return this.test.hash;
  }

  @Emit() testDeleted () {}

  @Emit() editTest (testId: string | number): EditTestEvent {
    return {
      testId: testId,
      canEdit: this.test.canDelete,
      testTypeId: this.test.generatorTestTypeId ?? TestTypeIdEnum.TEST
    };
  }

  gtmDownloadResource () {
    this.openDownloadModal();
    setTimeout(() => {
      this.$piwik.push({
        bookyear: categoryInfo(this.categoryKey, CategoryInfoLevel.BOOKYEAR),
        c_log_level: CLogLevel.METHOD,
        chapter: null,
        content_author: testAuthor(this.test.userId),
        content_difficulty: null,
        content_id: this.test.id.toString(),
        content_name: this.test.title,
        content_set: null,
        content_status: testActivityInfo(this.test.published),
        content_subtype: 'Paczka ZIP',
        content_type: ContentType.TEST,
        content_version: this.test.dateModify,
        education_level: categoryInfo(this.categoryKey, CategoryInfoLevel.EDUCATION_LEVEL),
        event: EventName.DOWNLOAD_RESOURCE,
        method: categoryInfo(this.categoryKey, CategoryInfoLevel.METHOD),
        page_path: this.$route.path,
        section: null,
        subject: categoryInfo(this.categoryKey, CategoryInfoLevel.SUBJECT)
      });
    });
  }

  onDeleteModalClose () {
    this.showDeleteTestModal = false;
    this.onDownloadModalCLose();
  }

  getTestLabelName (testType: TestTypeIdEnum): TranslateResult {
    if (testType === TestTypeIdEnum.TEST) return TestsAndExamsName;
    if (testType === TestTypeIdEnum.WORK_CARD) return WorkCardName;
    return '';
  }

  @Emit('download-modal-close') onDownloadModalCLose () {}

  askDeleteModal (testId: number) {
    this.testToDeleteId = testId;
    this.showDeleteTestModal = !this.showDeleteTestModal;
  }

  formatDate (date: string): string {
    const newDate = date.split(' ')[0];
    return new Intl.DateTimeFormat('pl-PL').format(new Date(newDate));
  }
}
