
import { Component, Vue, Prop, PropSync, Emit } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import TestTile from './TestTile';
import {
  Paginator
} from '@/shared/components';
import TestPreview from './TestPreview';
import {
  FETCH_TEST_PREVIEW,
  SET_ANSWER_CARD_NOT_DOWNLOADABLE,
  USER_NOTIFY
} from '@/store/list';
import { EditTestEvent } from '@/models/tests';
import { CLogLevel, ContentType, EventName } from '@/models/gtm';
import { categoryInfo } from '@/helpers/category-key-info';
import { CategoryInfoLevel } from '@/models/category-info';
import { LayoutModel } from '@/store/layout-store/types';
import { contentSetInfo } from '@/helpers/content-set-info';
import { testActivityInfo } from '@/helpers/activity-info';
import { RouteName } from '@/router/models';
import { ROLES } from '@/config';
import DownloadTestModal
  from '@/views/Generator/TestDatabase/components/TestTiles/TestTile/DownloadTestModal/DownloadTestModal.vue';
import { TestModel, TestsResponseModel } from '@/models';

const AppStore = namespace('AppStore');
const TestStore = namespace('TestStore');
const LayoutStore = namespace('LayoutStore');
const AuthStore = namespace('AuthStore');

@Component({
  name: 'test-tiles',
  components: { TestTile, Paginator, TestPreview, DownloadTestModal }
})
export default class TestTiles extends Vue {
  @PropSync('page') currentPage!: number;
  @Prop() lastPage!: number;

  categoryLabel = '';
  testTitle = '';
  isModalOpen = false;
  testSkillLabel = '';
  showDownloadTestModal = false;

  @AppStore.State('categoryKey') categoryKey: string;
  @AppStore.State('userRole') userRole: ROLES[];
  @AuthStore.State('isUserLogged') isUserLogged: boolean;
  @LayoutStore.State('layout') layout: LayoutModel;
  @TestStore.State('testPreView') testPreview: TestModel;
  @TestStore.State('tests') tests: TestsResponseModel;

  mounted () {
    if (window.location.hash.length > 1) {
      const hashArr = window.location.hash.split(',');
      this.openTestInPreview(hashArr[hashArr.length - 1]);
    }
  }

  @TestStore.Action(FETCH_TEST_PREVIEW) fetchTest: (hash: string) => Promise<void>;
  @TestStore.Mutation(SET_ANSWER_CARD_NOT_DOWNLOADABLE) setAnswerCardDownloadable: any;
  @AppStore.Action(USER_NOTIFY) notify: any;

  @Emit() changePagination () {}
  @Emit() testDeleted () {}
  async openTestInPreview (urlHash: string) {
    await this.fetchTest(urlHash);
    this.setAnswerCardDownloadable();
    this.categoryLabel = this.testPreview.exerciseCategoryLabel as string;
    this.testTitle = this.testPreview.title;
    this.isModalOpen = true;
    this.testSkillLabel = this.testPreview.exerciseSkillLabel as string;
    this.showPreviewGtmPush();
  }

  async downloadModalOpen (urlHash: string) {
    await this.fetchTest(urlHash);
    this.setAnswerCardDownloadable();
    this.isModalOpen = false;
    this.showDownloadTestModal = true;
  }

  get isRedactorGT (): boolean {
    return this.userRole.includes(ROLES.REDACTOR_GT);
  }

  get showOndorio (): boolean | null {
    return this.layout.user && this.layout.user.isOndorio;
  }

  showPreviewGtmPush (): void {
    this.$piwik.push({
      event: EventName.SHOW_PREVIEW,
      c_log_level: CLogLevel.METHOD,
      subject: categoryInfo(this.categoryKey, CategoryInfoLevel.SUBJECT),
      method: categoryInfo(this.categoryKey, CategoryInfoLevel.METHOD),
      education_level: categoryInfo(this.categoryKey, CategoryInfoLevel.EDUCATION_LEVEL),
      bookyear: categoryInfo(this.categoryKey, CategoryInfoLevel.BOOKYEAR),
      chapter: null,
      section: null,
      content_type: ContentType.TEST,
      content_name: this.testPreview.title ?? null,
      content_status: testActivityInfo(this.testPreview.published),
      content_version: this.testPreview.dateModify,
      content_author: this.testPreview.author,
      content_difficulty: null,
      content_subtype: null,
      page_path: this.$route.path,
      content_set: contentSetInfo(this.testPreview.variants),
      content_id: this.testPreview.id!.toString()
    });
  }

  editTest (obj: EditTestEvent) {
    if (obj.testId) {
      if (obj.canEdit) {
        this.$router.push({
          name: RouteName.TEST_EDITOR,
          params: {
            testId: obj.testId.toString(),
            testTypeId: obj.testTypeId.toString(),
            categoryKey: this.$route.params.categoryKey
          }
        });
      } else {
        this.$router.push({
          name: RouteName.TEST_COMPOSER,
          params: {
            categoryKey: this.$route.params.categoryKey,
            testTypeId: obj.testTypeId.toString()
          },
          query: {
            testToClone: obj.testId.toString()
          }
        });
      }
    }
  }

  closeModal () {
    this.isModalOpen = false;
  }
}
