
import { Component, Emit, Prop, PropSync, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { DELETE_TEST_ID } from '@/store/list';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton
} from '@/shared/components';

const TestStore = namespace('TestStore');

@Component({
  name: 'DeleteTestModal',
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton
  }
})
export default class DeleteTestModal extends Vue {
  @Prop() testId: any;
  @Prop() testTitle: string;
  @Prop() isOndorioInfoVisible: boolean;
  @PropSync('active') value: boolean;

  @TestStore.Action(DELETE_TEST_ID) deleteTestStore: any;

  @Emit() close () {}
  @Emit() testDeleted () {}

  async removeTest () {
    await this.deleteTestStore(this.testId);
    this.testDeleted();
    this.close();
  }
}
