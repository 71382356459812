
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { DownloadTestLinks, Modal, ModalBody, ModalHeader } from '@/shared/components';

@Component({
  name: 'download-test-modal',
  components: { DownloadTestLinks, Modal, ModalBody, ModalHeader }
})
export default class DownloadTestModal extends Vue {
  @Prop() active: boolean;
  @Prop() files: string[];
  @Prop() testTitle: string;
  @Prop() isRedactorGt: boolean;
  @Prop() isOndorio: boolean;

  @Emit() close () {}
}
